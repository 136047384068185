import _ from 'underscore';
import { v4 as uuidv4 } from 'uuid';
import { GET, POST, PUT, DELETE } from './utils';

declare global {
    namespace Product {
        type Article = {
            aid: string;
            allAuthors: string;
            authors: Array<{
                participantId: string;
                firstName: string;
                middleName: string;
                lastName: string;
                name: string;
                prefix: string;
                email: string;
                role: ParticipantRole;
            }>;
            ctaReceivedDate?: string;
            ctaType?: string;
            deleted: false;
            doi: string;
            editorialRefCode?: string;
            id: string;
            invitedInAs: true;
            journalId: string;
            name: string;
            productionReceivedDate: string;
            productionStartedDate: string;
            pubStatus: string;
            pubStatusDate: string;
            pubStatusDates: {
                ARTICLE_ACCEPTED: string;
            };
            submittedDate: string;
            walsParticipation: true;
            workflowState: string;
        };

        export type Journal = {
            coverImageUrl: string;
            doi: string;
            eissn: string;
            groupCode: string;
            id: string;
            journalUuid: string;
            inactiveForAs: boolean;
            journalId: string;
            journalTitle: string;
            name: string;
            merged: boolean;
            onlineOpen: boolean;
            openAccess: boolean;
            pissn: string;
            prices: Price[];
            revenueModel: 'OA' | 'OO';
            status: string;
            walsParticipation: boolean;
            wolUrl: string;
        };
    }

    type Funder = {
        alternateNames: string[];
        countryCode: string;
        id: string;
        name: string;
        parentId: string;
        parents: Array<{
            id: string;
            name: string;
        }>;
    };

    type ChinaPayDetails = {
        conversionRate: number;
        currency: string;
        finalPrice: number;
        subTotal: number;
        surcharge: number;
        tax: number;
    };

    type Order = {
        article: Product.Article;
        billingAddress?: Address;
        cancelled: boolean;
        correspondenceAddress?: Address;
        draft: boolean;
        existing: boolean;
        journal: Product.Journal;
        ooUniqueId: string;
        orderDate: number;
        orderStatusDate: number;
        orderType: 'AUTHOR_PAID' | 'FUNDER_PAID';
        paid: boolean;
        paymentDetails?: {
            paymentMethod: string;
            displayedCreditCardNumber: string;
            displayedCreditCardExpiry: string;
            cardVerified: boolean;
        };
        pricingDetails?: {
            currency: string;
            basePrice: number;
            discountAmount: number;
            taxAmount: number;
            totalAmount: number;
        };
        vatTaxDetails?: {};
        woaFunderId?: string;
        billToType?: string;
    };

    type Country = {
        id: string;
        name: string;
    };
}

/* eslint-disable no-param-reassign */
export function mapOrderToDefValues(order) {
    order.researchPrograms = order.researchPrograms || [];
    order.discountDetails = order.discountDetails || {};
    order.paymentDetails = order.paymentDetails || {};
    order.correspondenceAddress = order.correspondenceAddress || {};
    order.billingAddress = order.billingAddress || {};
    order.vatTaxDetails = order.vatTaxDetails || {};
    order.pricingDetails = order.pricingDetails || {};
    order.pricingDetails.taxAmount = order.pricingDetails.taxAmount || 0;
    order.pricingDetails.pricesUnavailable = order.pricingDetails.pricesUnavailable || false;
    return order;
}

function mapOrderToReducedDefValues(order) {
    const newOrder = order;
    newOrder.researchPrograms = order.researchPrograms || [];
    newOrder.correspondenceAddress = order.correspondenceAddress || {};
    newOrder.vatTaxDetails = order.vatTaxDetails || {};
    newOrder.pricingDetails = order.pricingDetails || {};
    newOrder.pricingDetails.taxAmount = order.pricingDetails.taxAmount || 0;
    return newOrder;
}

export function addDiscountsIds(order) {
    if (!order || !order.prices) {
        return order;
    }

    const prices = order.prices.map(price => {
        if (!price?.appliedDiscounts) {
            return price;
        }
        const appliedDiscounts = price.appliedDiscounts.map(discount => ({ ...discount, id: uuidv4() }));
        return { ...price, appliedDiscounts };
    });
    return { ...order, prices };
}

function restoreRequestOrderWoaFunderNullValue(requestOrder) {
    return function (responseOrder) {
        responseOrder.woaFunderId =
            requestOrder.woaFunderId === null && !responseOrder.woaFunderId ? null : responseOrder.woaFunderId;
        return responseOrder;
    };
}

const runTimeCache = {};

let appinfoCached;

export const appinfo = {
    async get() {
        if (!appinfoCached) {
            appinfoCached = await GET('/appinfo');
        }

        return appinfoCached;
    },
};

export const version = async (): Promise<
    { UI: { version: string; status: 'ONLINE' } } & {
        [backendService: string]: {
            version: { buildVersion: string; environment: string };
            status: 'OFFLINE' | 'ONLINE';
        };
    }
> => {
    let backend;

    try {
        backend = (await appinfo.get()).components;
    } catch (error) {
        backend = { appservices: { status: 'OFFLINE' } };
    }

    return {
        UI: {
            status: 'ONLINE',
            // @ts-ignore
            version: APP_VERSION,
        },
        ...backend,
    };
};

export const payment = {
    async getSocopayEnabled(): Promise<boolean> {
        return await GET('/payment/socopayEnabled');
    },
    async savePaymentMethod(articleId, paymentDetails: {}, isEditOrderFlow = false): Promise<boolean> {
        return await POST(`/articles/${articleId}/combinedFlow/savePaymentMethod`, { isEditOrderFlow, paymentDetails });
    },
};

export const onlineopen = {
    async getInstitutions(journalId: string): Promise<Array<{ id: string; name: string }>> {
        return GET(`/onlineOpenOrder/getDiscountInstitutions/${journalId}`);
    },
    async hasAdditionalDiscounts(journalId: string): Promise<boolean> {
        return GET(`/onlineOpenOrder/hasAdditionalDiscounts/${journalId}`);
    },
    async hasSocietyDiscounts(journalId: string): Promise<boolean> {
        return GET(`/onlineOpenOrder/hasSocietyDiscounts/${journalId}`);
    },

    async saveAndPreview(order) {
        const url = '/onlineOpenOrder/preview';
        order.billToType = order?.billingAddress?.billToType;
        const trimmedOrder = { ...order };
        if (trimmedOrder.orderType === 'FUNDER_PAID') {
            delete trimmedOrder.billingAddress;
        }

        trimmedOrder.researchPrograms = (order.researchPrograms || []).map(p => ({ ...p }));
        _.each(trimmedOrder.researchPrograms, researchPrograms => {
            delete researchPrograms._id;
            delete researchPrograms.researchFunder.alternateNames;
            delete researchPrograms.researchFunder.parents;
        });

        const responseOrder = await POST(url, trimmedOrder);

        return mapOrderToDefValues(restoreRequestOrderWoaFunderNullValue(trimmedOrder)(responseOrder));
    },
    async saveForLater(order) {
        const responseOrder = await POST('/onlineOpenOrder/saveForLater', order);

        return mapOrderToDefValues(restoreRequestOrderWoaFunderNullValue(order)(responseOrder));
    },

    validateDiscountData(order) {
        return PUT('/onlineOpenOrder/validateDiscountData', order);
    },
    validateTaxData(order) {
        return PUT('/onlineOpenOrder/validateTaxData', order);
    },
};

export const openaccess = {
    async getOrder(articleId, correlationId, socopayOrderNumber = undefined) {
        const order = await GET(
            '/openAccessOrder/getOrder',
            socopayOrderNumber ? { articleId, correlationId, socopayOrderNumber } : { articleId, correlationId },
        );

        return order;
    },
    validateTaxData(order) {
        return PUT('/openAccessOrder/validateTaxData', order);
    },
};

export const dropdown = {
    autocomplete(key, params) {
        return POST('/autocomplete/dropdown', { key, ...params });
    },
};

export const profile = {
    getAddresses: (): Promise<{
        billingAddress: Address;
        correspondenceAddress: Address;
        shippingAddress: Address;
    }> => GET('/userprofile/userAddresses/'),
    getAffiliations: (): Promise<Affiliation[]> => GET('/userprofile/affiliations/'),
    getEmailDetails: (): Promise<{
        primaryEmailAddr: string;
        searchFullName: boolean;
        skipSecondaryValidation: boolean;
    }> => GET('/userprofile/emailDetails/'),
    getProfileInfo: (): Promise<Profile> => GET('/userprofile/profileInfo/'),
    makeFavorite: (journalId: string): Promise<void> => POST('/userprofile/preferredJournals', { journalId }),
    preferredJournals: (): Promise<Product.Journal[]> => GET('/userprofile/preferredJournals'),
    publishedInJournals: (): Promise<Product.Journal[]> => GET('/userprofile/publishedInJournals'),
    removeFromFavorites: (journalId: string): Promise<void> => DELETE(`/userprofile/preferredJournals/${journalId}`),
};

export const address = {
    getCountries(phrase: string, count?: number, offset?: number) {
        return dropdown.autocomplete('countries', { count, offset, phrase });
    },
    getCountriesAll: async (search: string, isFunderCountries?: boolean): Promise<Country[]> => {
        const countries = await address.getCountries(search, 300);
        const res = [];

        if (isFunderCountries) {
            const { items: woaCountries } = await address.getWoaCountries();

            countries.forEach(c => {
                c.id = c.id.toLowerCase();

                if (woaCountries.includes(c.id)) {
                    res.push(c);
                }
            });
        }

        return isFunderCountries ? res : countries;
    },
    getInstitutions(phrase, count, offset) {
        return dropdown.autocomplete('institutions', { count, offset, phrase });
    },
    getStates(parent, phrase, count, offset) {
        if (parent && parent.id) {
            return dropdown.autocomplete('states', {
                count,
                offset,
                parentId: parent.id,
                phrase,
            });
        }
        return Promise.resolve([]);
    },
    getWoaCountries() {
        return GET('/search/as-institution-woa-funder-country-list');
    },
    validate: async (
        addressToValidate: AddressLocation,
    ): Promise<{
        error?: any;
        addresses: AddressLocation[];
    }> => {
        const payload = await POST('/validateAddress', addressToValidate);

        const addresses = payload.addresses.map(address2 => {
            const allowedFields = [
                'addressLine1',
                'addressLine2',
                'city',
                'stateCode',
                'stateName',
                'zipCode',
                'countryName',
                'countryCode',
            ];

            const result = {};
            allowedFields.forEach(key => {
                result[key] = address2[key];
            });
            return result;
        });

        return {
            ...payload,
            addresses,
        };
    },
};

export const orders = {
    async auditSanctionCountryData(
        articleId: string,
        billingSanctionCountryCode: string,
    ): Promise<{
        payload: boolean;
    }> {
        return await POST(
            `/sanctionCountry/audit/${articleId}/billingCountry?countryCode=${encodeURI(billingSanctionCountryCode)}`,
        );
    },
    async checkBillingCountryAndOrderStatus(
        country: string,
        isEditOrderFlow: boolean,
        articleId: string,
    ): Promise<{
        sanctionCountryWithNewOrder: boolean;
        sanctionCountryWithSubscriptionLicense: boolean;
        sanctionCountryWithOOOrderEdit: boolean;
        remainingSanctionedBillingAttempts: number;
    }> {
        return POST(`/sanctionCountry/validate/${articleId}/billingCountry`, {
            billingCountry: country,
            editOrder: isEditOrderFlow,
        });
    },
    async getOrdersWithReducedDefaults(type?: string, fromDate?: string, toDate?: string): Promise<Order[]> {
        // response is passed to <Article />
        const payload = await GET('/commonOrder/getAllOrders', { fromDate, toDate, type });

        payload
            .filter(o => o.order)
            .map(o => o.order)
            .forEach(mapOrderToReducedDefValues);

        return payload;
    },
    async getVatTaxRelevanceDetails(countryCode: string): Promise<{
        relevance: 'VAT' | 'TAX';
        country: string;
        countryCode: string;
        regex: string;
    }> {
        if (!runTimeCache[`vatTaxRelevance/${countryCode}`]) {
            runTimeCache[`vatTaxRelevance/${countryCode}`] = await GET(`/commonOrder/vatTaxRelevance/${countryCode}`);
        }
        return runTimeCache[`vatTaxRelevance/${countryCode}`];
    },
};

export const product = {
    getArticleAndJournal: async (
        articleId: string,
    ): Promise<{
        article: Product.Article;
        journal: Product.Journal;
    }> => GET(`/product/getArticleAndJournal/${articleId}`),
    getAuthors: articleId => GET(`/product/${articleId}/authors`),
    getCountryCurrency: countryCode => GET(`/product/getCountryCurrency/${countryCode}`),
    getCountryVATFormat: countryCode => GET(`/product/getCountryVATFormat/${countryCode}`),
    getJournalById: async (id: string): Promise<Product.Journal> => GET(`/product/getJournal/${id}`),
};

export const institutions = {
    async getInstitutionsList(
        query: string,
        size: number = 10,
        offset: number = 0,
        options?: {
            blacklist?: string[];
            institutionsIdFromProfile?: string[];
            userLocation?: { userCountryCode?: string };
        },
        countryCode?: string,
        isWoaFunder?: boolean,
    ): Promise<{ hasMore: boolean; list: Institution[] }> {
        const { blacklist = [], institutionsIdFromProfile = [], userLocation = {} } = options || {};
        const params = {
            '-id': blacklist,
            countryCode: countryCode || userLocation?.userCountryCode,
            id: institutionsIdFromProfile.filter(id => !blacklist.includes(id)),
            isWoaFunder,
            offset,
            query: query || '',
            size: size + 1,
        };

        const payload = await GET('/search/institutions', params);
        const list = payload.items;

        let hasMore = false;
        if (list.length > size) {
            list.pop();
            hasMore = true;
        }

        return {
            hasMore,
            list,
        };
    },
};

export const funders = async (params: {
    authorId?: string;
    countryCode?: string;
    q: string;
    size?: number;
    offset?: number;
}): Promise<Funder[]> => (await GET('/search/funders', params)).items;
