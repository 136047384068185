import { getUrlParam, deprecateUrlParam } from 'app/blocks/middleware/url-params';
import { REST } from './utils';

const { GET, POST } = REST('/registration');

declare global {
    namespace Registration {
        type ExistedUser = {
            firstName?: string;
            institution: string;
            lastName?: string;
            middleName?: string;
            orcidId?: string;
            primaryEmailAddr?: string;
            publishedInJournal: string;
            suffix?: string;
            title?: string;
        };

        type Area = { id: string; name: string };
    }
}

export function appendInvitationTokenToParams(originParams) {
    const invitationToken = getUrlParam('invitationToken');
    if (invitationToken) {
        return { ...originParams, invitationToken };
    }

    return originParams;
}

export function removeInvitationToken() {
    deprecateUrlParam('invitationToken');
}

export const getInvitation = (
    invitationId: string,
): Promise<{
    country: { countryName: string; countryCode: string };
    firstName: string;
    inviters: Array<{ email: string; name: string }>;
    lastName: string;
    primaryEmailAddr: string;
}> => GET('/invitation', { id: invitationId });

export const register = async (params: {
    areaOfInterest?: string;
    countryCode: string;
    firstName: string;
    lastName: string;
    orcidId?: string;
    password: string;
    primaryEmailAddr: string;
    searchFullName: boolean;
    sendEmailFlag: boolean;
    skipSecondaryValidation: boolean;
    wileyTermsOfUseConsent: boolean;
    chinaPIPLConsent: boolean;
}): Promise<{ registrationToken: string; verificationRequired: boolean }> => {
    const res = await POST(
        '/register',
        appendInvitationTokenToParams({ ...params, returnUrl: getUrlParam('returnUrl') }),
    );

    removeInvitationToken();

    return res;
};

export const registerFinalize = (userId: string): Promise<{ emailId: string; returnUrl?: string }> =>
    POST('/finalize/', { userId });

export const verifyEmailExists = (email: string): Promise<void> => POST('/verify/email', { primaryEmailAddr: email });

export const resendVerificationEmail = (email: string): Promise<void> =>
    POST('/verify/resend', { primaryEmailAddr: email, returnUrl: getUrlParam('returnUrl') });

export const getListOfInterests = (): Promise<Registration.Area[]> => REST.GET('/asdata/areasOfInterests/');
